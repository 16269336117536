import { observer } from "mobx-react";
import useGlobalStore from "../../stores/GlobalStore/useGlobalStore";
import FadeIn from "../animated/FadeIn/FadeIn";
import styles from "./SignInModal.module.scss";
import JesusModalPicture from "../../assets/jesus-modal.svg";
import GoogleIcon from "../../assets/google-icon.svg";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import AuthStore from "../../stores/AuthStore/AuthStore";
import { useState } from "react";
import useImagePreloader from "../../utils/useImagePreloader";
import cn from "classnames";

const preloadSrcList = [
    GoogleIcon,
    JesusModalPicture,
];

const SignInModal = () => {

    const store = useGlobalStore();
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const { imagesPreloaded } = useImagePreloader(preloadSrcList);

    const login = useGoogleLogin({
        onSuccess: (response) => {
            setIsLoggingIn(true);
            axios.post(`${process.env.REACT_APP_API_URL}/auth/login/google`, { token: response.access_token })
                .then((response) => {
                    AuthStore.saveToken(response.data.token, store)
                    store.setIsSignInOpen(false);
                    setIsLoggingIn(false);
                });
        },
        onError: () => {
            setIsLoggingIn(false);
        },
    });

    return store.isSignInOpen && imagesPreloaded ? 
    (
        <FadeIn>
          <div className={styles["about-modal"]}>
            <div className={styles["modal-content"]}>
                <img src={JesusModalPicture} alt="jesus" />
                <span className={styles["modal-header"]}>Welcome</span>
                <span className={styles["modal-header-small"]}>Log In or Sign up to Continue</span>
                <div className={styles["modal-button-group"]}>
                    <button onClick={() => login()} className={cn(styles["modal-button"], isLoggingIn && styles["hidden"])}>
                        <img src={GoogleIcon} alt="google" />
                    </button>
                </div>
            </div>
          </div>
        </FadeIn>)
        : (
        null
    );
};

export default observer(SignInModal);