import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GlobalStoreProvider } from './stores/GlobalStore/useGlobalStore';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <GoogleOAuthProvider clientId={"1075019190588-68m4ar0ods8keli8t3nu72s8dnepuek2.apps.googleusercontent.com"}>
    <GlobalStoreProvider>
      <App />
    </GlobalStoreProvider>
  </GoogleOAuthProvider>
);
