import { Message, SenderType } from "../../../types"
import styles from "./MessageBubble.module.scss";
import cn from "classnames";
import { useEffect, useRef } from "react";
import useGlobalStore from "../../../stores/GlobalStore/useGlobalStore";
import MessageLoader from "./MessageLoader/MessageLoader";

export interface MessageBubbleProps {
    message: Message;
}

function getBubbleClassName(sender: SenderType): string {
    switch(sender) {
        case "User":
            return styles["user-bubble"];
        case "Jesus":
            return styles["jesus-bubble"];
        case "Server":
            return styles["error-bubble"];
    }
}

const MessageBubble = ({ message }: MessageBubbleProps) => {

    const store = useGlobalStore();

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref?.current) {
            ref.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [store.messages.length]);

    return (
        <div ref={ref} className={cn(styles["bubble"], getBubbleClassName(message.sender))}>
            {message.value === undefined ? (
                <MessageLoader />
            ) : (
                message.value
            )
            }
        </div>
    );
}

export default MessageBubble;