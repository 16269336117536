import React from "react";
import { observer } from "mobx-react";
import styles from "./ChatPage.module.scss";
import SoundIcon from "../../assets/sound-icon.svg";
import Input from "../../components/Input/Input";
import ChatComponent from "../../components/ChatComponent/ChatComponent";
import useGlobalStore from "../../stores/GlobalStore/useGlobalStore";
import FadeIn from "../../components/animated/FadeIn/FadeIn";
import cn from "classnames";
import TrashIcon from "../../assets/trash-icon.svg";
import UserInfo from "../../components/UserInfo/UserInfo";

const ChatPage = () => {
    const store = useGlobalStore();

    return (
        <>
            <div className={styles["page"]}>
                {!store.isMobile ? (
                    <FadeIn delay={200}>
                        <button onClick={() => store.setIsAudioPlaying(!store.isAudioPlaying)} className={styles["sound-button"]}>
                            <div className={styles["sound-button__container"]}>
                                <div className={cn(styles["sound-button__cross"], !store.isAudioPlaying && styles["sound-button__cross__appended"])} />
                                <img className={styles["sound-button__image"]} src={SoundIcon} alt="sound-icon"/>
                            </div>
                        </button>
                    </FadeIn>
                ) : (
                    <>
                    {store.userInfo && 
                        <button
                            onClick={() => store.clearMessages()}
                            className={cn(styles["clear-button"], store.messages.length && styles["clear-button__show"])}>
                                <img src={TrashIcon} alt="trash-icon" />
                                Clear Conversations
                        </button>}
                    </>
                )}

                <FadeIn delay={200}>
                    <div className={styles["button-group"]}>
                        {!store.userInfo ? (
                            <>
                                {!store.userInfoLoading ? (
                                        <>
                                            <button onClick={() => store.setIsSignInOpen(true)} className={styles["sign-in-button"]}>
                                                Log In
                                            </button>
                                            <button onClick={() => store.setIsSignUpOpen(true)} className={styles["sign-up-button"]}>
                                                Sign Up
                                            </button>
                                        </>
                                    )
                                    : (
                                        <div>
                                            Loading...
                                        </div>
                                    )
                                }
                            </>
                        )
                        : (
                            <UserInfo userInfo={store.userInfo} />
                        )
                        }
                    </div>
                </FadeIn>

                <div className={styles["chat-box"]}>
                    <ChatComponent />
                </div>

                <FadeIn delay={600}>
                    <Input />
                </FadeIn>
            </div>

            <div className={cn(styles["page__gradient"], !store.messages.length && styles["low-opacity"])} />
        </>
    );
};

export default observer(ChatPage);