import { observer } from "mobx-react";
import useGlobalStore from "../../stores/GlobalStore/useGlobalStore";
import GoogleIcon from "../../assets/google-icon.svg";
import FadeIn from "../animated/FadeIn/FadeIn";
import styles from "./SignUpModal.module.scss";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import AuthStore from "../../stores/AuthStore/AuthStore";
import cn from "classnames";
import { useState } from "react";

const SignUpModal = () => {
    const store = useGlobalStore();
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const login = useGoogleLogin({
        onSuccess: (response) => {
            setIsLoggingIn(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/auth/login/google`, { token: response.access_token })
                .then((response) => {
                    AuthStore.saveToken(response.data.token, store)
                    store.setIsSignUpOpen(false);
                    setIsLoggingIn(false);        
                });
        },
    });

    return store.isSignUpOpen ? 
    (
        <FadeIn>
          <div className={styles["about-modal"]}>
            <div className={styles["modal-content"]}>
                <span className={styles["modal-header"]}>Create your account</span>
                <span className={styles["modal-header-small"]}>
                    Sign up to talk to Jesus AI
                    <br />
                    Learn and explore spiritual world
                </span>
                <div className={styles["modal-label"]}>
                    <div className={styles["modal-label__separator"]} />
                    <span className={styles["modal-label__text"]}>Sign Up With</span>
                    <div className={styles["modal-label__separator"]} />
                </div>
                <div className={styles["modal-button-group"]}> 
                    <button onClick={() => login()} className={cn(styles["modal-button"], isLoggingIn && styles["hidden"])}>
                        <img src={GoogleIcon} alt="google" />
                    </button>
                </div>
            </div>
          </div>
        </FadeIn>) 
        : (
        null
    );

};

export default observer(SignUpModal);