import { observer } from "mobx-react";
import useGlobalStore from "../../stores/GlobalStore/useGlobalStore";
import styles from "./ChatComponent.module.scss";
import MessageBubble from "./MessageBubble/MessageBubble";
import MessageTemplates from "./MessageTemplates/MessageTemplates";
import { v4 } from "uuid";
import {useEffect} from "react";

const ChatComponent = () => {
    const store = useGlobalStore();

    useEffect(() => {
        store.fetchConversationUuid();
    }, [store]);

    return (
        <div className={styles["chat"]}>
            {!store.messages.length ? (
                <div className={styles["message-templates__container"]}>
                    <div className={styles["message-templates"]}>
                        <MessageTemplates />
                    </div>
                </div>
            ): (
                <div className={styles["messages"]}>
                    {store.messages.map((message) => <MessageBubble key={v4()} message={message} />)}
                </div>
            )}
        </div>
    );
}

export default observer(ChatComponent);