import styles from "./FadeIn.module.scss";
import cn from "classnames";
import { useEffect, useState } from "react";

export interface FadeInProps {
    children: React.ReactElement;
    delay?: number;
}

const FadeIn = ({ children, delay = 0 }: FadeInProps) => {

    const [animationStarted, setAnimationStarted] = useState(false);

    useEffect(() => {
        if (delay) {
            setTimeout(() => {
                setAnimationStarted(true);
            }, delay)
        } else setAnimationStarted(true)
    }, [delay])

    return ( 
        <div className={cn(styles["fade-before"], animationStarted && styles["fade-after"])}>
            {children}
        </div>
    );
}

export default FadeIn;