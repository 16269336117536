import { observer } from "mobx-react";
import styles from "./VideoPage.module.scss";
import useGlobalStore from "../../stores/GlobalStore/useGlobalStore";
import { useEffect, useRef } from "react";
import SoundIcon from "../../assets/sound-icon.svg";
import cn from "classnames";

const VideoPage = () => {
    const store = useGlobalStore();

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        setTimeout(() => {
            if (videoRef?.current) {
                videoRef.current.play();
            }
        }, 2000);
    }, [videoRef]);

    const handleVideoEnded = () => {
        store.setPageState("Chatting");
    };
    
    const handleVideoSkip = () => {
        if (videoRef?.current) {
            videoRef.current.pause();
        }
        handleVideoEnded();
    };

    return (
        <div className={styles["page"]}>
            <video ref={videoRef} src={store.videoUrl} className={styles["video-background"]} playsInline muted onEnded={handleVideoEnded} />
            <div className={styles["overlay"]}>
                <span className={styles["overlay__text"]}>
                    In the beginning
                    <br/> 
                    was the Word
                </span>
            </div>
           {!store.isMobile && (
                <button onClick={() => store.setIsAudioPlaying(!store.isAudioPlaying)} className={styles["sound-button"]}>
                    <div className={styles["sound-button__container"]}>
                        <div className={cn(styles["sound-button__cross"], !store.isAudioPlaying && styles["sound-button__cross__appended"])} />
                        <img className={styles["sound-button__image"]} src={SoundIcon} alt="sound-icon"/>
                    </div>
                </button>
            )}
            <button onClick={handleVideoSkip} className={styles["skip-button"]}>Skip Video</button>
        </div>
    )
};

export default observer(VideoPage);