import { observer } from "mobx-react";
import styles from "./MessageTemplates.module.scss";
import TemplateCard from "./TemplateCard/TemplateCard";
import { v4 } from "uuid";
import useGlobalStore from "../../../stores/GlobalStore/useGlobalStore";
import FadeIn from "../../animated/FadeIn/FadeIn";

const templates: { text: string, onClick?: () => void }[] = [
    {
        text: "How can I find peace in troubled times?",
    },
    {
        text: "What is the purpose of life according to Jesus?",
    },
    {
        text: "How can I forgive those who have wronged me?",
    },
];

const MessageTemplates = () => {

    const store = useGlobalStore();

    return (
        <div className={styles["container"]}>
            <FadeIn delay={200}>
                <h1 className={styles["header"]}>My child, ask me about life, faith and love</h1>
            </FadeIn>
            {templates.map((template, index) => 
                <FadeIn delay={(index + 1) * 200} key={v4()}>
                    <TemplateCard
                        onClick={() => store.checkIfLoggedIn(() => store.pushMessage({ value: template.text, sender: "User"}))}
                        text={template.text} />
                </FadeIn>
            )}
        </div>
    )
};

export default observer(MessageTemplates);