import { useEffect } from "react";


export default function useOutsideClickListener(ref: React.RefObject<HTMLElement>, callback: Function) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref?.current && !ref?.current.contains(event.target)) {
          callback();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, callback]);
}