import styles from "./MessageLoader.module.scss";
import cn from "classnames";

const MessageLoader = () => {


  return (
    <div className={styles["loader"]}>
      <div className={cn(styles["dot"], styles["dot__first"])} />
      <div className={cn(styles["dot"], styles["dot__second"])} />
      <div className={cn(styles["dot"], styles["dot__third"])} />
    </div>
  );
};

export default MessageLoader;