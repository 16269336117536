import React, { useLayoutEffect } from "react";
import { createContext, useState, useContext } from "react";
import GlobalStore from "./GlobalStore";

const GlobalStoreContext = createContext<GlobalStore | null>(null);

export interface GlobalStoreProviderProps {
    children: React.ReactElement;
}

export const GlobalStoreProvider = ({ children }: GlobalStoreProviderProps) => {
    const [globalStore] = useState(new GlobalStore(window.innerWidth));

    useLayoutEffect(() => {
        function updateSize() {
            globalStore.setInnerWidth(window.innerWidth);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, [globalStore]);

    return <GlobalStoreContext.Provider value={globalStore}>{children}</GlobalStoreContext.Provider>
}

// This hook can be called anywhere in application and will return one instance of global store. Don't forget to mark components as observers for them to listen to store state changes.
const useGlobalStore = (): GlobalStore => {
    const store = useContext(GlobalStoreContext);

    if (store === null) throw new Error("useGlobalStore should be inside GlobalStoreProvider");

    return store; 
}

export default useGlobalStore;