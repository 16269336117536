import { observer } from "mobx-react";
import useGlobalStore from "../../stores/GlobalStore/useGlobalStore";
import FadeIn from "../animated/FadeIn/FadeIn";
import CloseIcon from "../../assets/close-icon.svg";
import ModalPicture from "../../assets/balance-modal.svg";
import styles from "./NoBalanceModal.module.scss";


const NoBalanceModal = () => {
    const store = useGlobalStore();

    return store.isNoBalanceModalOpen ? 
    (
        <FadeIn>
          <div className={styles["about-modal"]}>
            <div className={styles["modal-content"]}>
              <button onClick={() => store.setIsNoBalanceModalOpen(!store.isNoBalanceModalOpen)} className={styles["modal-button"]}>
                <img src={CloseIcon} alt="close-icon" />
              </button>
              <img className={styles["modal-picture"]} src={ModalPicture} alt="modal-pic" />
              <span className={styles["modal-header"]}>You've used all your messages</span>
              <span className={styles["modal-text"]}>
                10 free messages will refill tomorrow, come back tomorrow for more conversations
              </span>
            </div>
          </div>
        </FadeIn>) 
        : (
        null
    );
}

export default observer(NoBalanceModal);