import { observer } from "mobx-react";
import styles from "./TemplateCard.module.scss";
import ChevronRight from "../../../../assets/chevron-right.svg";

export interface TemplateCardProps {
    text: string;
    onClick?: () => void;
}

const TemplateCard = ({ text, onClick }: TemplateCardProps) => {

    return (
        <div onClick={onClick} className={styles["card"]}>
            <h3 className={styles["card__text"]}>{text}</h3>
            <img className={styles["card__chevron"]} src={ChevronRight} alt="chevron-right" />
        </div>
    );
};

export default observer(TemplateCard);