import styles from "./UserInfo.module.scss";
import ChevronDown from "../../assets/chevron-down.svg";
import { UserInfoType } from "../../stores/GlobalStore/GlobalStore";
import { useRef, useState } from "react";
import cn from "classnames";
import FadeIn from "../animated/FadeIn/FadeIn";
import useOutsideClickListener from "../../utils/useOutsideClickListener";
import useGlobalStore from "../../stores/GlobalStore/useGlobalStore";

export interface UserInfoProps {
    userInfo: UserInfoType;
}

const UserInfo = ({ userInfo }: UserInfoProps) => {

    const store = useGlobalStore();

    const [isOpen, setIsOpen] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    const fetchAndSetIsOpen = (value: boolean) => {
        if (value) {
            store.fetchUserInfo();
        }
        setIsOpen(value);
    }

    useOutsideClickListener(ref, () => fetchAndSetIsOpen(false));

    return (
        <div ref={ref} className={styles["user-info"]}>
            <img onClick={() => fetchAndSetIsOpen(!isOpen)} className={cn(styles["user-info__chevron"], isOpen && styles["user-info__chevron__open"])} src={ChevronDown} alt="chevron-down" />
            <div onClick={() => fetchAndSetIsOpen(!isOpen)} className={styles["user-info__avatar__container"]}>
                <img className={styles["user-info__avatar"]} src={userInfo.avatar} alt="avatar" />
                <div className={styles["user-info__avatar__container__overlay"]} />
            </div>
            {isOpen && (
                <FadeIn delay={0}>
                    <div className={styles["user-info__details"]}>
                        <span className={styles["user-info__details__email"]}>{userInfo.email}</span>
                        <div role="none" className={cn(styles["user-info__details__separator"], styles["user-info__details__separator__top"])} />
                        <span className={styles["user-info__details__balance"]}>
                            Balance: <span className={styles["user-info__details__balance__bold"]}>{userInfo.balance}</span>
                        </span>
                        <span className={styles["user-info__details__message"]}>
                            Get 10 messages
                            <br/>
                            everyday for free
                        </span>
                        <div role="none" className={cn(styles["user-info__details__separator"], styles["user-info__details__separator__bottom"])} />
                        <button onClick={() => store.toggleIsAboutOpen()} className={styles["user-info__details__button"]}>
                            About
                        </button>
                        <button onClick={() => store.logout()} className={styles["user-info__details__button"]}>
                            Log Out
                        </button>
                    </div>
                </FadeIn>
                
            )}
        </div>
    );
}

export default UserInfo;