import axios from "axios";
import GlobalStore, { UserInfoType } from "../GlobalStore/GlobalStore";
import Cookies from "js-cookie";

class AuthStore {

    static get token() {
        return Cookies.get("token");
    }

    static logout() {
        Cookies.remove("token");
    }

    static saveToken(token: string, globalStore: GlobalStore) {
        Cookies.set("token", token);
        globalStore.fetchUserInfo();
    }

    static async getUserInfo(): Promise<UserInfoType | undefined> {
        const token = AuthStore.token;
        if (token) {
            const userInfoResponse = await axios.get(`${process.env.REACT_APP_API_URL}/user`, { 
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return userInfoResponse.data as UserInfoType;
        }
        return undefined;
    }

    static async getConversationUuid(): Promise<string | undefined> {
        const token = AuthStore.token;
        if (token) {
            const uuidResponse = await axios.post(`${process.env.REACT_APP_API_URL}/conversations`, 
            {}, 
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return uuidResponse.data.uuid;
        }
        return undefined;
    }

    static get isLoggedIn() {
        return !!Cookies.get("token");
    }

}

export default AuthStore;