import { observer } from "mobx-react";
import styles from "./Input.module.scss";
import SendIcon from "../../assets/send-icon.svg";
import useGlobalStore from "../../stores/GlobalStore/useGlobalStore";
import { useRef } from "react";
import TrashIcon from "../../assets/trash-icon.svg";
import cn from "classnames";

const Input = () => {
    const store = useGlobalStore();
    const inputRef = useRef<HTMLInputElement>(null);

    const handleSendMessage = () => {
        store.checkIfLoggedIn(() => {
            if (store.getInputValue === "" || store.isMessageResponseLoading) return;
            store.pushMessage({ sender: "User", value: store.getInputValue });
            if (inputRef.current) {
                inputRef.current.value = "";
                store.setInputValue("");
            }
        });
    };

    return (
        <div className={styles["container"]}>
            {!store.isMobile && (
                <button
                    onClick={() => store.clearMessages()}
                    className={cn(styles["clear-button"], store.messages.length && styles["clear-button__show"])}>
                        <img src={TrashIcon} alt="trash-icon" />
                        Clear Conversations
                </button>
            )}

            <div className={styles["input-container"]}>
                <input
                    maxLength={400}
                    ref={inputRef}
                    onKeyDown={(e) => e.code === "Enter" && handleSendMessage()}
                    onChange={(e) => store.setInputValue(e.target.value)}
                    className={styles["input"]}>
                </input>
                <button
                    disabled={store.getInputValue === ""}
                    onClick={handleSendMessage}
                    className={styles["send-button"]}>
                    <div className={styles["send-button__container"]}>
                        <img className={styles["icon"]} src={SendIcon} alt="send-icon" />
                    </div>
                </button>
            </div>

            <span className={styles["info-text"]}>
                The Jesus AI is not meant to replace or represent any religious figure. 
                The generated content is for educational purposes only and may contain inaccuracies and biases.
            </span>
        </div>
    );
};

export default observer(Input);