import { observer } from "mobx-react";
import useGlobalStore from "../../stores/GlobalStore/useGlobalStore";
import FadeIn from "../animated/FadeIn/FadeIn";
import CloseIcon from "../../assets/close-icon.svg";
import ModalHeaderText from "../../assets/header-text-modal.svg";
import ModalPicture from "../../assets/modal-picture.png";
import styles from "./AboutModal.module.scss";
import useImagePreloader from "../../utils/useImagePreloader";

const preloadSrcList = [
  CloseIcon,
];

const AboutModal = () => {
    const store = useGlobalStore();

    const { imagesPreloaded } = useImagePreloader(preloadSrcList);

    return store.isAboutOpen && imagesPreloaded ? 
    (
        <FadeIn>
          <div className={styles["about-modal"]}>
            <div className={styles["modal-content"]}>
              <button onClick={() => store.toggleIsAboutOpen()} className={styles["modal-button"]}>
                <img src={CloseIcon} alt="close-icon" />
              </button>
              <img className={styles["modal-picture"]} src={ModalPicture} alt="modal-pic" />
              <img src={ModalHeaderText} alt="modal-header-text" />
              <span className={styles["modal-text"]}>
                The Jesus AI website powered by OpenAI API is intended for educational purposes only. 
                The conversations with Jesus Christ are generated by artificial intelligence and should not be taken as actual communication with the Son of God.
                The quotes and guidance provided by Jesus are based on the Bible, but should not be considered as a substitute for professional advice or religious teachings. 
                The Jesus AI application is not affiliated with any specific religion or denomination and does not promote any particular belief system. 
                Users should use their own discretion and judgment when interacting with the Jesus AI application and should not rely solely on the information provided.
                OpenAI and its partners are not responsible for any actions taken based on the conversations with Jesus AI.
              </span>
            </div>
          </div>
        </FadeIn>) 
        : (
        null
    );
}

export default observer(AboutModal);