import { action, computed, flow, flowResult, makeObservable, observable } from "mobx";
import { Message, PageState } from "../../types";
import axios, { AxiosError, AxiosResponse } from "axios";
import AuthStore from "../AuthStore/AuthStore";

type MessageResponse = {
    answer: string;
};

export type UserInfoType = {
    name: string;
    email: string;
    balance?: number;
    avatar: string;
};

class GlobalStore {

    //Shows current page state, accessible only from getter
    private pageState: PageState = "VideoLoading";

    messages: Message[] = [];

    userInfoLoading: boolean = false;
    
    isNoBalanceModalOpen: boolean = false;

    isMessageResponseLoading: boolean = false;
    
    private inputValue: string = "";
    
    isAboutOpen = false;
    
    isSignUpOpen = false; 
    
    isSignInOpen = false;
    
    videoUrl: string = "";
    
    conversationUuid?: string;
    
    isAudioPlaying: boolean;
    
    userInfo?: UserInfoType;

    constructor(public innerWidth: number) {

        makeObservable<GlobalStore, "pageState" | "fetchMessageResponseFlow" | "inputValue" | "fetchUserInfoFlow" | "fetchConversationUuidFlow">(this, {
            isNoBalanceModalOpen: observable,
            setIsNoBalanceModalOpen: action,
            innerWidth: observable,
            setInnerWidth: action,
            isMobile: computed,
            userInfo: observable,
            setUserInfo: action,
            pageState: observable,
            setPageState: action,
            getCurrentPageState: computed,
            messages: observable,
            pushMessage: action,
            clearMessages: action,
            isMessageResponseLoading: observable,
            fetchMessageResponseFlow: flow,
            inputValue: observable,
            logout: action,
            setInputValue: action,
            getInputValue: computed,
            isAboutOpen: observable,
            toggleIsAboutOpen: action,
            isSignUpOpen: observable,
            setIsSignUpOpen: action,
            isSignInOpen: observable,
            setIsSignInOpen: action,
            videoUrl: observable,
            setVideoUrl: action,
            conversationUuid: observable,
            isAudioPlaying: observable,
            setIsAudioPlaying: action,
            fetchUserInfoFlow: flow,
            userInfoLoading: observable,
            fetchConversationUuidFlow: flow,
        });

        const audioState = localStorage.getItem("audioState");
        if (audioState) {
            this.isAudioPlaying = audioState === "true" ? true : false;
        } else {
            this.isAudioPlaying = true;
        }

        this.fetchUserInfo();
    }

    get isMobile() {
        return this.innerWidth < 480;
    }

    setIsNoBalanceModalOpen(newValue: boolean) {
        this.isNoBalanceModalOpen = newValue;
    }

    setInnerWidth(newInnerWidth: number) {
        this.innerWidth = newInnerWidth;
    }

    setUserInfo(userInfo: UserInfoType) {
        this.userInfo = userInfo;
    }

    checkIfLoggedIn(callback: Function) {
        const logged = AuthStore.isLoggedIn;
        if (logged) {
            callback();
        } else {
            this.setIsSignInOpen(true);
        }
    }

    setIsSignInOpen(newValue: boolean) {
        this.isSignInOpen = newValue;
    }

    setIsSignUpOpen(newValue: boolean) {
        this.isSignUpOpen = newValue;
    }

    setIsAudioPlaying(newValue: boolean) {
        this.isAudioPlaying = newValue;
        localStorage.setItem("audioState", this.isAudioPlaying ? "true" : "false");
    }

    toggleIsAboutOpen() {
        this.isAboutOpen = !this.isAboutOpen;
    }

    get getInputValue() {
        return this.inputValue;
    }

    setInputValue(newValue: string) {
        this.inputValue = newValue;
    }

    pushMessage(newMessage: Message) {
        this.messages.push(newMessage);
        if (this.messages?.[this.messages.length - 1].sender === "User")
            this.fetchMessageResponse();
    }

    clearMessages() {
        this.messages = [];
        this.conversationUuid = undefined;
    }

    get getCurrentPageState(): PageState {
        return this.pageState;
    }

    setPageState(newPageState: PageState) {
        this.pageState = newPageState;
    }

    setVideoUrl(newValue: string) {
        this.videoUrl = newValue;
    }

    fetchMessageResponse() {
        if (!AuthStore.isLoggedIn) return;

        const message = this.messages[this.messages.length - 1];
        if (message.sender === "Jesus" || !message.value) throw new Error("Can not fetch when sender is Jesus");

        return flowResult(this.fetchMessageResponseFlow(message.value));
    }

    logout() {
        AuthStore.logout();
        this.userInfo = undefined;
        this.clearMessages();
    }

    private *fetchMessageResponseFlow(messageValue: string) {
        this.isMessageResponseLoading = true;
        this.messages.push({
            value: undefined,
            sender: "Jesus",
        });

        try {
            if (!this.conversationUuid) {
                yield this.fetchConversationUuid();
            }
    
            const response: AxiosResponse<MessageResponse> = yield axios.post(`${process.env.REACT_APP_API_URL}/conversations/${this.conversationUuid}`, {
                message: messageValue,
            }, {
                headers: {
                    Authorization: `Bearer ${AuthStore.token}`
                }
            });
    
            this.messages.pop();
            this.messages.push({
                value: response.data.answer,
                sender: "Jesus",
            });
        } catch (e: any) {
            const axiosError = e as AxiosError;
            if (axiosError.code === "ERR_BAD_REQUEST") {
                this.setIsNoBalanceModalOpen(true);
            }
            this.messages.pop();
            this.messages.push({ value: `Could not generate response`, sender: "Server" });
        } finally {
            this.isMessageResponseLoading = false;
        }
    }

    fetchUserInfo() {
        return flowResult(this.fetchUserInfoFlow());
    }

    private *fetchUserInfoFlow() {
        this.userInfoLoading = true;
        try {
            this.userInfo = yield AuthStore.getUserInfo();
        } finally {
            this.userInfoLoading = false;
        }
    }

    fetchConversationUuid() {
        return flowResult(this.fetchConversationUuidFlow());
    }

    private *fetchConversationUuidFlow() {
        this.conversationUuid = yield AuthStore.getConversationUuid();
    }
}

export default GlobalStore;