import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import AboutModal from './components/AboutModal/AboutModal';
import useGlobalStore from './stores/GlobalStore/useGlobalStore';
import ChatPage from './pages/ChatPage/ChatPage';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import VideoPage from './pages/VideoPage/VideoPage';
import { PageState } from './types';
import cn from "classnames";
import Sound from "./assets/bg-audio.mp3";
import './App.css';
import SignUpModal from './components/SignUpModal/SignUpModal';
import SignInModal from './components/SignInModal/SignInModal';
import NoBalanceModal from './components/NoBalanceModal/NoBalanceModal';

function currentPage(currentPage: PageState): React.ReactElement {
  switch(currentPage) {
    case "VideoLoading":
      return <LoadingPage />;
    case "VideoPlaying":
      return <VideoPage />;
    case "Chatting":
      return <ChatPage />;
  }
}

function App() {
  const store = useGlobalStore();

  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    store.fetchUserInfo();
  }, [store]);

  useEffect(() => {
    if (!store.isMobile && audioRef?.current) {
      audioRef.current.volume = 0.03;
    }
  // eslint-disable-next-line
  }, [audioRef, store.isMobile]);

  useEffect(() => {
    if (!store.isMobile && audioRef?.current) {
      if (store.isAudioPlaying) {
          audioRef.current?.play().catch(() => {
            document.addEventListener("click", () => {
              audioRef.current?.play();
            }, { once: true });
          });
      }
      if (!store.isAudioPlaying) {
        audioRef.current.pause();
      }
    }
  }, [audioRef, store.isAudioPlaying, store.isMobile]);

  return (
    <>
      <div className={cn("App", (store.isAboutOpen || store.isSignUpOpen || store.isSignInOpen || store.isNoBalanceModalOpen) && "blur")}>
        {currentPage(store.getCurrentPageState)}
      </div>
      <AboutModal />
      <SignUpModal />
      <SignInModal />
      <NoBalanceModal /> 
      {!store.isMobile && (
        <audio ref={audioRef} >
          <source src={Sound} type="audio/mp3"/>
        </audio>
      )}
    </>
  );
}

export default observer(App);
