import React, { useEffect, useRef } from "react";
import styles from "./Loader.module.scss";

export interface LoaderProps {
    progress: number;
}

const Loader = ({ progress }: LoaderProps) => {

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref?.current) {
            ref.current.style.width = `${progress}%`;
        }
    }, [ref, progress]);
    
    return (
        <div className={styles["loader"]}>
            <div ref={ref} className={styles["loader__inner"]} />
        </div>
    );
};

export default Loader;