import { observer } from "mobx-react";
import styles from "./LoadingPage.module.scss";
import HeaderText from "../../assets/header-text.svg";
import Video from "../../assets/video.mp4";
import {useEffect, useState} from "react";
import useGlobalStore from "../../stores/GlobalStore/useGlobalStore";
import axios, { AxiosProgressEvent } from "axios";
import Loader from "../../components/Loader/Loader";

const LoadingPage = () => {
    const store = useGlobalStore();

    const [progress, setProgress] = useState(0);

    useEffect(() => {
            axios.get(Video, 
            { 
                responseType: "blob",
                onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
                    if (progressEvent?.progress) {
                        setProgress(Math.floor(progressEvent.progress * 100));
                    }
                },
            })
            .then(response => {
                const videoUrl = (window.URL || window.webkitURL).createObjectURL(response.data);
                store.setVideoUrl(videoUrl);
                setTimeout(() => {
                    store.setPageState("VideoPlaying");
                }, 1000);
            });
    }, [store])

    return (
        <>
            <div className={styles["page"]}>
                <img className={styles["header-image"]} src={HeaderText} alt="header-text" />
                <h2 className={styles["second-header"]}>
                    The Teachings of Christ With The Aid of
                    <br/>
                    Artificial Intelligence
                </h2>
            </div>
            <div className={styles["loader"]}>
                <Loader progress={progress} />
            </div>
        </>
    );
};

export default observer(LoadingPage);